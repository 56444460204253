import * as React from 'react';
import { permutateFeetDimensions, permutateInchesDimensions } from './utils';
import { useMemo } from 'react';
import { FormikFieldWrapper } from '../../Formik';
import { OptionType } from 'styleguide/components/Formik/Combobox/Combobox';

interface Props {
  _key: string;
  min: number;
  max: number;
  units: 'inches' | 'feet';
  label: string;
  onChange?: (value: OptionType<string | number>) => void;
}

const CustomDimensionSelect = ({ _key, min, max, units, label, onChange }: Props) => {
  const fn = units === 'feet' ? permutateFeetDimensions : permutateInchesDimensions;
  const options = useMemo(() => fn(min, max), [fn, min, max]);
  return (
    <FormikFieldWrapper
      name={_key}
      componentType="combobox"
      labelComponent={label}
      onChange={selectedOption => {
        if (onChange) {
          onChange(selectedOption);
        }
      }}
      options={options}
      wrapperClassName="mb-3"
    />
  );
};

export default CustomDimensionSelect;
