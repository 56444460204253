import * as React from 'react';
import Attribute from '../Attribute/Attribute';
import { AttributeGroup as AttributeGroupType } from 'api/persephone/productTypes';
import { Product } from '../Product';
import Grid from 'styleguide/components/Grid/Grid';
import QuoteContext from 'contexts/QuoteContext/QuoteContext';
import camelCase from 'lodash-es/camelCase';
import PersephoneContext from 'contexts/PersephoneContextContainer/PersephoneContext';
import TwoColumnFormField from 'styleguide/components/forms/Field/TwoColumnFormField';
import { useFormikContext } from 'formik';

interface Props extends AttributeGroupType {
  isCustomDimensionSelected: boolean;
  isTabsSelected: boolean;
  product: Product;
  singleColumn?: boolean;
  hideTooltips?: boolean;
}

const AttributeGroup = ({
  attributes,
  hidden,
  hideTooltips,
  isCustomDimensionSelected,
  isTabsSelected,
  name,
  product: { findAttribute, getHeightRange, getProductKey, getWidthRange },
  singleColumn,
}: Props) => {
  const quoteContext = React.useContext(QuoteContext);
  const persephoneContext = React.useContext(PersephoneContext);
  const productAlias = persephoneContext.catalog[
    camelCase(quoteContext.product.getProductKey())
  ].productAliases.find(a => a.key === quoteContext.alias);
  const { values } = useFormikContext();

  const isHidden = () => hidden && !quoteContext.showHiddenOptions;

  return (
    <div className={isHidden() ? 'hidden' : 'mb-5'}>
      <h6 className="mb-4 font-hvBold text-lg leading-4">{name}</h6>
      <Grid.Container>
        <Grid noPadding>
          <Grid.Row className="!justify-start">
            {attributes.map((elem, i) => {
              if (
                ['width', 'height', 'tab-paper-type', 'tab-mylar', 'number-of-tabs'].includes(elem.key) ||
                elem.key.match(/^(tab-)([1-9]|1[0-2])(-name)$/) ||
                elem.key.match(/^(tab-)([1-9]|1[0-2])(-page)$/)
              ) {
                return null;
              }

              if (elem.key === 'tab-dividers' && isTabsSelected) {
                const tabPaperType = findAttribute('tab-paper-type');
                const mylar = findAttribute('tab-mylar');
                const numberOfTabs = findAttribute('number-of-tabs');
                const tabArray = Array.from(
                  { length: parseInt(values[numberOfTabs.key], 10) },
                  (_, index) => index + 1,
                );

                return (
                  <Grid.Col md={singleColumn ? 12 : 6}>
                    <Attribute key={i} _key={elem.key} {...elem} />
                    <Attribute _key={tabPaperType.key} {...tabPaperType} hidden={false} />
                    <Attribute _key={mylar.key} {...mylar} hidden={false} />
                    <Attribute _key={numberOfTabs.key} {...numberOfTabs} hidden={false} />
                    {tabArray.map(tabIndex => {
                      const tabName = findAttribute(`tab-${tabIndex}-name`);
                      const tabPage = findAttribute(`tab-${tabIndex}-page`);

                      return (
                        <TwoColumnFormField>
                          <Attribute
                            _key={tabName.key}
                            {...tabName}
                            hidden={false}
                            validate={value => {
                              if (value.length) {
                                return null;
                              }
                              return 'Text on tab is required';
                            }}
                          />
                          <Attribute
                            _key={tabPage.key}
                            {...tabPage}
                            hidden={false}
                            validate={value => {
                              if (Number(value)) {
                                return null;
                              }
                              return 'Tab page location is required';
                            }}
                          />
                        </TwoColumnFormField>
                      );
                    })}
                  </Grid.Col>
                );
              }

              if (elem.key === 'dimension' && isCustomDimensionSelected) {
                const width = findAttribute('width');
                const height = findAttribute('height');

                return (
                  <Grid.Col md={singleColumn ? 12 : 6}>
                    <Attribute key={i} _key={elem.key} {...elem} />
                    <TwoColumnFormField>
                      <Attribute
                        key={100}
                        _key={width.key}
                        {...width}
                        customDimension
                        customDimensionFeet={getProductKey() === 'banners'}
                        customDimensionRange={getWidthRange()}
                      />
                      <Attribute
                        key={200}
                        _key={height.key}
                        {...height}
                        customDimension
                        customDimensionFeet={getProductKey() === 'banners'}
                        customDimensionRange={getHeightRange()}
                      />
                    </TwoColumnFormField>
                  </Grid.Col>
                );
              }

              return elem.hidden || (!!productAlias && productAlias.hiddenTypes?.includes(elem.id)) ? null : (
                <Grid.Col key={i} md={singleColumn ? 12 : 6}>
                  <Attribute
                    _key={elem.key}
                    {...elem}
                    hideTooltips={hideTooltips}
                    minWidth={elem.key === 'dimension' ? getWidthRange()?.[0] : null}
                    minHeight={elem.key === 'dimension' ? getHeightRange()?.[0] : null}
                  />
                </Grid.Col>
              );
            })}
          </Grid.Row>
        </Grid>
      </Grid.Container>
    </div>
  );
};

export default AttributeGroup;
