import { Product as ProductType, Attribute } from 'api/persephone/productTypes';

export class Product {
  private product: ProductType = null;

  constructor(product: ProductType) {
    this.product = product;
  }

  public getProductKey = (): string => this.product.key;

  public getProductName = (): string => this.product.name;

  public getWidthRange = (): number[] => this.getSomeRange('width-range');

  public getHeightRange = (): number[] => this.getSomeRange('height-range');

  public getProduct = (): ProductType => this.getClonedProduct();

  public getKeyedObjectWithDefaultValues = (aliasKey: string) => {
    const attributeGroups = this.product.attributeGroups;
    const attributes = attributeGroups.flatMap(group => group.attributes);
    const alias = this.product.productAliases.find(a => a.key === aliasKey);
    const result = attributes.reduce((acc, attr) => {
      const aliasOverride = alias.defaultOverrides.find(dov => dov.productAttributeId === attr.id);
      if (aliasOverride) {
        acc[attr.key] = aliasOverride.key;
      } else {
        acc[attr.key] = this.getDefaultValue(attr);
      }

      return acc;
    }, {});
    return result;
  };

  public findAttribute = (key): Attribute =>
    this.product.attributeGroups
      .map(group => group.attributes)
      .flat()
      .find(attribute => attribute.key === key);

  private getSomeRange = (key): number[] => {
    const attr = this.findAttribute(key);
    if (!attr) {
      return [];
    }
    return [parseFloat(attr.values[0].name), parseFloat(attr.values[1].name)];
  };

  private getClonedProduct = () => JSON.parse(JSON.stringify(this.product));

  private getDefaultValue = (attribute: Attribute) => {
    if (!!attribute.defaultValue && attribute.defaultValue.length > 0) {
      return attribute.defaultValue;
    }

    if (attribute.optionType === true) {
      if (attribute.valueGroups.length === 0) {
        if (attribute.values.length === 0) {
          // TODO: add sentry/honeybadger
          throw new Error(`Attribute ${attribute.name} doesn't have default value`);
        } else {
          return attribute.values[0].key;
        }
      } else {
        return attribute.valueGroups[0].values[0].key;
      }
    }

    return '';
  };
}
